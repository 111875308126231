import styled from '@emotion/styled';
import { sakBlue, gray05 } from '../../colors';

// eslint-disable-next-line import/prefer-default-export
export const CandidateImg = styled.img`
  object-fit: cover;
  height: 480px;
`;

export const CandidateText = styled.div`
  font-size: 16px;
  margin-bottom: 48px;

  p {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
    margin-top: 48px;
  }
`;

export const BackButton = styled.button`
  cursor: pointer;
  padding: 5px 16px;
  border: 1px solid ${gray05};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  transition: border 200ms ease;

  &:hover,
  &:focus,
  &:active {
    border-color: ${sakBlue};
  }

  svg {
    margin-right: 10px;
  }
`;
