import styled from '@emotion/styled';
import { BOX_SHADOW } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const ShareWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 2rem auto;
  padding: 2rem;
  text-align: center;
`;

export const ShareContainer = styled.div`
  pointer-events: none;
  position: fixed;
  right: 48px;
  z-index: 99;
  width: 266px;
  bottom: 48px;

  @media (max-width: 768px) {
    right: 24px;
    bottom: 24px;
  }
`;

export const ThankYou = styled.div`
  display: block;
  padding: 16px;

  h4 {
    color: #fff;
    font-weight: 700;
  }
`;

export const ShareBtn = styled.div`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  margin-left: auto;
  background-color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: 1px solid #eaeaeb;
  transition: box-shadow 200ms ease;

  &:hover {
    box-shadow: ${BOX_SHADOW};
  }
`;

export const ShareBtnBlue = styled.div`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  margin-left: auto;
  background-color: #0076be;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: 1px solid transparent;
  transition: box-shadow 200ms ease;

  &:hover {
    box-shadow: ${BOX_SHADOW};
  }
`;

export const ShareActive = styled.div`
  pointer-events: all;
  background: #0076be;

  box-shadow: ${BOX_SHADOW};
  border-radius: 4px;
  p {
    color: white;
    margin: 0;
  }
`;

export const SingleShareWrapper = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  ${(props) =>
    props.print && '@media screen and (max-width: 768px) { display: none; }'}
  align-items: center;
  justify-content: space-between;

  span {
    color: #fff;
    transition: color 200ms ease;

    &:hover {
      color: #0076be;
    }
  }

  p,
  span {
    font-weight: bold;
  }

  &:hover {
    background-color: #fff;
    transition: background-color 200ms ease;

    p {
      color: #0076be;
    }
    .share-icon {
      color: white;
      background: #0076be;
      cursor: pointer;
      svg path {
        fill: white;
      }
    }
  }
`;

export const ShareIcons = styled.div`
  padding-bottom: 16px;
`;

export const ShareIcon = styled.div`
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaeb;
  transition: all 200ms ease;
`;
