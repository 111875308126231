import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCandidates } from './components/Candidates/actions';

// eslint-disable-next-line react/prop-types
function Main({ children }) {
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.candidates);
  useEffect(() => {
    /* istanbul ignore next */
    if (!candidates) {
      /* istanbul ignore next */
      // Load master data.
      dispatch(loadCandidates());
    }
  }, [dispatch, candidates]);

  return <>{children}</>;
}

export default Main;
